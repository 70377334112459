import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import {api} from '../../../../api/proto';
import useStore from '../../../../stores/useStore';
import CanceledSubscriptionInfo from './CanceledSubscriptionInfo';
import PaymentFailedInfo from './PaymentFailedInfo';
import PaymentFailingInfo from './PaymentFailingInfo';
import TrialPeriodExpired from './TrialPeriodExpired';
import TrialPeriodInfo from './TrialPeriodInfo';

interface BillingStatusProps {
  className?: string;
}

export const BillingStatus: React.FC<BillingStatusProps> = observer(({className}) => {
  const {activeWorkspace} = useStore();
  const {billing} = activeWorkspace;

  if (billing.paymentStatus === api.WorkspaceGetCurrentTariffResponse.PaymentStatus.PAS_FAILING) {
    return (
      <PaymentFailingInfo className={cn('w-100', className)} />
    );
  } else if (billing.paymentStatus === api.WorkspaceGetCurrentTariffResponse.PaymentStatus.PAS_FAILED) {
    return (
      <PaymentFailedInfo className={cn('w-100', className)} />
    );
  } else if (billing.trialEnabled) {
    return billing.currentTariffExpired ? (
      <TrialPeriodExpired className={cn('w-100', className)} />
    ) : (
      <TrialPeriodInfo className={cn('w-100', className)} />
    );
  } else if (!billing.autoRenew && billing.currentTariff) {
    return (
      <CanceledSubscriptionInfo className={cn('w-100', className)} />
    );
  }

  return null;
});

export default BillingStatus;